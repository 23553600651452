import { Controller } from '@hotwired/stimulus'
import { useIntersection } from 'stimulus-use'
import Swiper, { Autoplay } from 'swiper'

// Connects to data-controller="previews-carousel"
export default class extends Controller {
  connect () {
    useIntersection(this)

    this.swiper = new Swiper(this.element, {
      slidesPerView: 'auto',
      spaceBetween: 20,
      watchOverflow: true,
      grabCursor: true,
      loop: true,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
      },
      modules: [Autoplay]
    })

    this.swiper.autoplay.stop()
  }

  disconnect () {
    this.swiper.destroy()
  }

  appear () {
    this.swiper.autoplay.start()
  }

  disappear () {
    this.swiper.autoplay.stop()
  }
}
