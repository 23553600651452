import { Controller } from '@hotwired/stimulus'
import videoCanvas from 'video-canvas'

// Connects to data-controller="video-canvas"
export default class extends Controller {
  static targets = ['video', 'canvas']

  connect () {
    videoCanvas(this.videoTarget, {
      canvas: this.canvasTarget
    })

    this.videoTarget.play()
  }
}
